<template>
  <div class="panel">
    <customSelect :name="'Информация'">
      <div style="margin: 5px; padding: 5px">
        <div style="margin-left: 10px">
          <p style="font-size: 15px; font-weight: 600">Информация</p>
          <div style="margin: 10px">
            <div style="margin-top: 5px">
              <i>Расстояние:</i>
              {{ order.distance >= 0 ? order.distance + "км" : "Нет данных" }}
            </div>
            <div style="margin-top: 5px">
              <i>Расстояние(По городу):</i>
              {{
                order.distance_in_city >= 0
                  ? order.distance_in_city + "км"
                  : "Нет данных"
              }}
            </div>
            <div style="margin-top: 5px">
              <i>Начальный адрес:</i> <br />
              {{ order.from_address.address }}
            </div>
            <div
              style="margin-top: 5px"
              v-for="(address, i) in order.to_addresses"
              :key="i"
            >
              <i>Конечный адрес:{{ i + 1 }}</i> <br />
              {{ address.address }}
            </div>
          </div>
        </div>
      </div>
    </customSelect>
    <customSelect :name="'Статус'">
      <div style="margin: 5px; padding: 5px">
        <div style="margin-left: 10px">
          <p style="font-size: 15px; font-weight: 600">Статус</p>
          <div style="margin: 10px">
            <div style="margin-top: 5px">
              <i>Поступил:</i>
              {{ `(${order.created_at})` }}
            </div>
            <div>
              <i>Телефон:</i>
              {{ order.phone }}
              {{ order.from_address.address }}
              ->
              <div
                style="margin-top: 5px"
                v-for="(address, i) in order.to_addresses"
                :key="i"
              >
                {{ address.address }}
              </div>
              {{
                "Нарх" + " " + order.price + " " + "Статус" + " " + order.status
              }}
            </div>
            <div style="margin-top: 30px">
              <div
                style="margin-top: 5px"
                v-for="(history, i) in orderHistory"
                :key="i"
              >
                <i
                  ><a style="color: blue; text-decoration: underline"
                    >{{ history.status }}
                  </a></i
                >
                {{ `(${history.created_at})` }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </customSelect>
  </div>
</template>

<script>
import customSelect from "@/views/component/Map/component/customSelect.vue";
export default {
  props: ["order", "orderHistory"],
  components: {
    customSelect,
  },
};
</script>

<style lang="scss" scoped>
.panel {
  width: 30%;
  height: 760px;
  overflow: auto;
}
</style>
